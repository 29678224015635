<template>
  <div class="physical">
    <div class="list">
      <div
        :class="[active==index ? ('active'+index+' box box'+index) : 'box box'+index]"
        v-for="(item,index) in list"
        :key="index"
        @click="handleStatus(index)"
      >
        <el-button
          v-show="active==index"
          type="text"
          @click="dialogFormVisible = true"
          style="width:20px;height: 20px;"
        >
          <img src="../../assets/img/physical/编组 13.png" alt="">
        </el-button>
        <div class="title">{{ item.title }}</div>
        <div class="unit">{{ item.unit }}</div>
        <div class="date">更新时间：{{ item.date }}</div>
        <div class="arrowhead" v-show="status"></div>
      </div>
    </div>
    <div class="main" v-show="showChart">
      <!-- <div class="arrowhead"></div> -->
      <ECharts :signList="signList"></ECharts>
    </div>
    <el-dialog title="体重记录" :visible.sync="dialogFormVisible" style="width: 800px;">
      <el-form :model="form">
        <div class="record">
          <el-form-item label="记录时间" :label-width="formLabelWidth">
            <el-input
              v-model="form.date"
              autocomplete="off"
              style="width:260px;height: 36px;"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="体重" :label-width="formLabelWidth">
            <el-input v-model="form.weight" autocomplete="off" style="width:89px;height: 36px;"></el-input>
            <span>KG</span>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="text" style="width:16px;height:16px">
              <img src="../../assets/img/physical/保存.png" alt="">
            </el-button>
            <el-button type="text" style="width:16px;height:16px">
              <img src="../../assets/img/case/删除 (6)备份.png" alt="">
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClick">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- <SignsRecord :state.sync="dialogStatus"></SignsRecord> -->
    <!-- <div class="chart"></div> -->
  </div>
</template>
<script>
import { postSignAddApi, getSignListApi } from "@/api/visit";
export default {
  components: {
    ECharts: () => import("./components/Echarts.vue"),
    // SignsRecord: () => import("./components/SignsRecord.vue")
  },

  data() {
    return {
      active: 0,
      signList: [],
      // dialogStatus: false,
      status: false,
      showChart: true,
      list: [
        { title: "体重", unit: "65kg", date: "2023-2-22 16:23:20" },
        { title: "血压", unit: "90/60 mmHg", date: "2023-2-22 16:23:20" },
        { title: "血糖", unit: "5.3 mmol/L", date: "2023-2-22 16:23:20" },
        { title: "体温", unit: "35.6℃", date: "2023-2-22 16:23:20" },
        { title: "脉搏", unit: "100次/分", date: "2023-2-22 16:23:20" },
        { title: "呼吸", unit: "35次/分", date: "2023-2-22 16:23:20" },
      ],
      dialogFormVisible: false,
      form: {
        weight: "",
        date: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        patienceId: "725876d857854124832f8ff592a44669",
        signType: "",
        signValue: "",
      },
      formLabelWidth: "120px",
    };
  },

  mounted() {
    console.log(12345, this.$route.params.patience_name);
    this.handleSignList();
    this.getDictList();
    this.getDataList();
  },

  methods: {
    getDataList() {
      let that = this;
      let teamsId = "";
      let doctorId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        doctorId = JSON.parse(localStorage.userInfo).id;
      }
      let params = {
        signType: 1,
        pageNo: 1,
        pageSize: 999,
        teamsId: teamsId,
        doctorId: doctorId,
        patienceId: JSON.parse(localStorage.patientInfo).patience_id,
      };

      that.dataListLoading = true;
      that
        .$http({
          url: that.$http.adornUrl("/mbglPatienceSign/list"),
          method: "get",
          params: params,
        })
        .then(({ data }) => {
          if (data.status) {
          }
        });
    },
    // 获取字典
    async getDictList() {
      let _this = this;
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      const { data } = await this.$http({
        url: _this.$http.adornUrl("/dict/list?type=" + "体征类型"),
        method: "get",
        params: {
          teamsId,
          pageSize: 999,
        },
      });

      if (data.status) {
      }
    },
    handleStatus(val) {
      // console.log(111);
      // console.log(EChartsData)
      this.active = val;
    },

    async handleSignList() {
      console.log(777777, this.form.patienceId);
      let params = {
        patienceId: this.form.patienceId,
        signType: "0",
      };
      //  这是获取列表数据
      // const list = await getSignListApi(params);
      // this.signList = list.data;
    },

    async handleClick() {
      console.log("表单数据", this.form);
      const res = await postSignAddApi(this.form);
      console.log("111", res);
      this.dialogFormVisible = false;
      if (res.status) {
        this.$message.success(res.status);
      } else {
        this.$message.error(res.status);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.physical {
  .list {
    width: 100%;
    height: 164px;
    display: flex;
    justify-content: space-around;
    // background-color: aqua;
    .box0 {
      background: url("../../assets/img/physical/icon-1.png") center center
        no-repeat;
      background-size: 100% 100%;
    }
    .active0 {
      border: 1px solid #ffbca9;
    }
    .active1 {
      border: 1px solid #b1efa6;
    }
    .box1 {
      background: url("../../assets/img/physical/icon-2.png") center center
        no-repeat;
      background-size: 100% 100%;
    }
    .active2 {
      border: 1px solid #9edeec;
    }
    .box2 {
      background: url("../../assets/img/physical/icon-3.png") center center
        no-repeat;
      background-size: 100% 100%;
    }
    .active3 {
      border: 1px solid #c2c2f8;
    }
    .box3 {
      background: url("../../assets/img/physical/icon-4.png") center center
        no-repeat;
      background-size: 100% 100%;
    }
    .active4 {
      border: 1px solid #e5c2f5;
    }
    .box4 {
      background: url("../../assets/img/physical/icon-5.png") center center
        no-repeat;
      background-size: 100% 100%;
    }
    .box5 {
      background: url("../../assets/img/physical/icon-6.png") center center
        no-repeat;
      background-size: 100% 100%;
    }
    .active5 {
      border: 1px solid #f8a5bc;
    }
    .box {
      width: 251px;
      height: 164px;

      border-radius: 8px;
      margin-right: 19px;
      position: relative;
      .arrowhead {
        display: none;
        position: absolute;
        bottom: -35px;

        width: 0;
        height: 0;
        // margin: 20px auto;
        border: 30px solid transparent;
        border-bottom-color: #f7f9fb;
      }
      .el-button {
        position: absolute;
        right: 13px;
        top: 13px;
        width: 20px;
        height: 20px;
        border: 0px;
        img {
          position: absolute;
          top: 0;
          left: calc(50% - 10px);
          width: 20px;
          height: 20px;
        }
      }
      .title {
        width: 32px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin: auto;
        margin-top: 34px;
      }
      .unit {
        width: 100%;
        height: 30px;
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        text-align: center;
        margin-top: 14px;
        white-space: nowrap;
      }
      .date {
        width: 147px;
        height: 14px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin: auto;
        margin-top: 14px;
        white-space: nowrap;
      }
    }
  }
  .main {
    width: 100%;
    height: 485px;
    // background-color: aquamarine;
    overflow: hidden;
  }
  .el-dialog {
    position: relative;
    .record {
      display: flex;
      justify-content: space-around;
    }
    .dialog-footer {
      position: absolute;
      right: 32px;
      bottom: 24px;
    }
  }
}
</style>